import React from 'react'
import SEO from "../common/SEO";
import HeaderTopNews from '../common/header/HeaderTopNews';
import HeaderOne from '../common/header/HeaderOne';
import FooterFour from '../common/footer/FooterFour';
import BrandThree from '../elements/brand/BrandThree';
import AboutFour from '../elements/about/AboutFour';
import AccordionOne from "../elements/accordion/AccordionOne";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import CounterUpFour from "../elements/counterup/CounterUpFour";
import TeamFour from "../elements/team/TeamFour";
import TimelineTwo from "../elements/timeline/TimelineTwo";
import Separator from "../elements/separator/Separator";
import NewsletterOne from '../common/footer/NewsletterOne';
import Copyright from "../common/footer/Copyright";
import { Link } from "react-router-dom";

import { FiChevronRight, FiArrowRight } from 'react-icons/fi';
const AboutUs = () => {
    return (
        <>
        
            <SEO title="About Us" />
            <main className="page-wrapper">
                
                <HeaderOne btnStyle="btn-small round btn-icon" />
         
                {/* Start Slider Area  */}
                 <div className="slider-area slider-style-1 height-650 bg_image" data-black-overlay="7" style={{backgroundImage: `url(${process.env.PUBLIC_URL}/images/about/about-us-1.png)`}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner pt--80 text-center">

                                    <h1 className="title display-one">We are the industry's most experienced cross-platform indicator and strategy developers.</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> 
                {/* End Slider Area  */}
                {/* Start Service Area  */}
                <div className="service-area rn-section-gapTop">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign="text-center"
                                    radiusRounded=""
                                    subtitle="Our Team"
                                    title="Who are we?"
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="content">
                                    <h3 className="title">Our team began as a network of software developers working for major and minor financial companies alike.</h3>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <p className="mb--10">We have created thousands of successful indicators and strategies used by traders around the world, and combine decades of years of experience. At Traders Custom we've come together to provide world-class development skills for the retail and enterprise trader alike.</p>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Service Area  */}

                <Separator />

                {/* Start Service Area  */}
                <div className="rn-service-area rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign="text-center"
                                    radiusRounded=""
                                    subtitle="We stand out from the pack. Big time."
                                    title="Why us?"
                                    description="Our development and ethical philosophy has endeared us to many repeat customers who recognize the outstanding value we provide."
                                />
                            </div>
                        </div>
                        <AccordionOne
                            serviceStyle="service__style--1 bg-color-blackest radius mt--25"
                            textAlign="text-center"
                        />
                    </div>
                </div>
                {/* End Service Area  */}

                <Separator />




                {/* <div className="service-area rn-section-gapTop">
                    <div className="container">
                        <div className="row">
                            <NewsletterOne newsletetrStyle="rn-newsletter-default" extraClass="border-top-bottom" />

                        </div>
                    </div>
                </div> */}
            </main>

            <Copyright />

        </>
    )
}

export default AboutUs;
