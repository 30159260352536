import React from 'react';
import ContactForm from "./ContactForm";
import GoogleMapStyle from "./GoogleMapStyle";
import { FiHeadphones, FiMail, FiMapPin } from "react-icons/fi";
import SelectableCard from './SelectableCard';
import SectionTitle from '../sectionTitle/SectionTitle';
import CalltoActionSix from '../calltoaction/CalltoActionSix';
import StandardDropzone from './StandardDropzone';
import Separator from "../separator/Separator";
const ThankYou = (props) => {



    function handleTextEntry(event) {
        let input = event.target.value.toString();
        props.setProjectDescription(input);
    }

    return (
        <>
            <div className="row row--15">
                <div className="col-lg-12">
                    <div className="rn-contact-address mt_dec--30">
                        <div className="row">


                            <div className="col-lg-12 mt--10">
                                <SectionTitle
                                    textAlign="text-left"
                                    radiusRounded=""
                                    subtitle="Success!"
                                    title="Thank you!"
                                />

                                <p className="text-left">We have successfully received your request, and will contact you with a development effort estimate within 1 business day. We're excited to start working with you!</p>

                            </div>


                        </div>
                        <Separator />

                    </div>
                </div>

            </div>


        </>
    )
}
export default ThankYou;