import React from 'react';
import ContactForm from "./ContactForm";
import GoogleMapStyle from "./GoogleMapStyle";
import { FiHeadphones, FiMail, FiMapPin } from "react-icons/fi";
import SelectableCard from './SelectableCard';
import SectionTitle from '../sectionTitle/SectionTitle';
import CalltoActionSix from '../calltoaction/CalltoActionSix';
import StandardDropzone from './StandardDropzone';

const RequestStepThree = (props) => {



    function handleTextEntry(event) {
        let input = event.target.value.toString();
        props.setProjectDescription(input);
    }

    return (
        <>
            <div className="row row--15">
                <div className="col-lg-12">
                    <div className="rn-contact-address mt_dec--30">
                        <div className="row">


                            <div className="col-lg-12 mt--10">
                                <SectionTitle
                                    textAlign="text-left"
                                    radiusRounded=""
                                    subtitle="Step 3"
                                    title="Tell us what you're looking for."
                                />

                                <p className="text-left">What would you like your project to accomplish? We will use this description to provide you an estimate.</p>

                            </div>



                        </div>
                        <form className="contact-form-1">

                            <div className="row">
                                <div className='col-12'>


                                    <div className="form-group">
                                        <textarea
                                            name="message"
                                            placeholder="The details of your project"
                                            required
                                            onChange={handleTextEntry}
                                        >
                                            {props.projectDescription}
                                        </textarea>
                                    </div>
                                </div>

                            </div>

                            <div className="row">
                                <div className='col-12'>


                                    <p className="text-left">Any explanatory documents, source code files, images, or videos you would like to include with your request? These can really help us to understand your goals.</p>
                                    <p className="text-left">You can upload up to 10 files for a maximum total size of 20 MB.</p>

                                    <StandardDropzone

                                        files={props.files}
                                        setFiles={props.setFiles}
                                    />



                                </div>

                            </div>

                        </form>


                    </div>
                </div>
            </div>

            <div className="row mt--40 row--15">
                <div className="col-lg-12">
                </div>

            </div>
        </>
    )
}
export default RequestStepThree;