import React from 'react';
import ContactForm from "./ContactForm";
import GoogleMapStyle from "./GoogleMapStyle";
import { FiHeadphones, FiMail, FiMapPin } from "react-icons/fi";
import SelectableCard from './SelectableCard';
import SectionTitle from '../sectionTitle/SectionTitle';
import CalltoActionSix from '../calltoaction/CalltoActionSix';

const RequestStepTwo = (props) => {
    return (
        <>
            <div className="row row--15">
                <div className="col-lg-12">
                    <div className="rn-contact-address mt_dec--30">
                        <div className="row">


                            <div className="col-lg-12 mt--10">
                                <SectionTitle
                                    textAlign="text-left"
                                    radiusRounded=""
                                    subtitle="Step 2"
                                    title="Which platform(s) are you targeting?"
                                />

                                <p className="text-left">Please select the platform(s) that apply to your request.</p>

                            </div>


                            <SelectableCard
                                add={props.add}
                                remove={props.remove}
                                contains={props.contains}

                                title="TradingView"
                                image={`./images/brand/tv-color.png`}
                            />
                            <SelectableCard
                                add={props.add}
                                remove={props.remove}
                                contains={props.contains}

                                title="NinjaTrader"
                                image={`./images/brand/nt-color.png`}

                            />
                            <SelectableCard
                                add={props.add}
                                remove={props.remove}
                                contains={props.contains}

                                title="MetaTrader"
                                image={`./images/brand/mt-color.png`}

                            />


                            <SelectableCard
                                add={props.add}
                                remove={props.remove}
                                contains={props.contains}

                                title="ThinkOrSwim"
                                image={`./images/brand/tos-color.png`}
                            />
                            <SelectableCard
                                add={props.add}
                                remove={props.remove}
                                contains={props.contains}

                                title="TradeStation"
                                image={`./images/brand/ts-color.png`}

                            />
                            <SelectableCard
                                add={props.add}
                                remove={props.remove}
                                contains={props.contains}

                                title="Other"
                                image={`./images/brand/other-color.png`}

                            />
                        </div>



                    </div>
                </div>
            </div>

            <div className="row mt--40 row--15">
                <div className="col-lg-12">
                </div>

            </div>
        </>
    )
}
export default RequestStepTwo;