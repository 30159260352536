import React from 'react';
import { useState } from 'react';

import SEO from "../../common/SEO";
import Layout from "../../common/Layout";
import BreadcrumbOne from "../breadcrumb/BreadcrumbOne";
import SectionTitle from "../sectionTitle/SectionTitle";
import RequestStepOne from "./RequestStepOne";
import RequestStepTwo from "./RequestStepTwo";
import RequestStepThree from "./RequestStepThree";
import RequestStepFour from "./RequestStepFour";
import RequestStepFive from "./RequestStepFive";
import ThankYou from "./ThankYou";
import ErrorPage from "./ErrorPage";
import { useEffect } from "react";

import TimelineTwo from '../timeline/TimelineTwo';
import StepBar from './StepBar';
import axios from 'axios';
import AdditionalFeaturesStep from './AdditionalFeaturesStep';


const CustomRequest = () => {

    const [activeStep, setActiveStep] = useState(0);
    const [projectDescription, setProjectDescription] = useState("");
    const [senderName, setSenderName] = useState("");
    const [senderEmail, setSenderEmail] = useState("");
    const [validEmail, setValidEmail] = useState(false);
    const [selectedProjectTypes, setSelectedProjectTypes] = useState([]);
    const [selectedPlatforms, setSelectedPlatforms] = useState([]);
    const [files, setFiles] = useState([]);
    const [requestSubmitted, setRequestSubmitted] = useState(false);
    const [error, setError] = useState(false);
    const [errorText, setErrorText] = useState("Something went wrong! We might be overloaded with requests. Please contact us directly with your request at CustomRequests@TradersCustom.com");


    function addProjectType(projectType) {
        if (!projectTypesContains(projectType)) {
            setSelectedProjectTypes((prevState) => {
                let newArray = [...prevState, projectType];
                return newArray;
            });
        }
    }

    function projectTypesContains(projectType) {
        return selectedProjectTypes.includes(projectType);

    }

    function removeProjectType(projectType) {
        setSelectedProjectTypes((prevState) => {
            let newArray = prevState.filter((v) => {
                return v !== projectType;
            });
            return newArray;
        });
    }


    function addPlatform(platform) {
        if (!platformsContains(platform)) {
            setSelectedPlatforms((prevState) => {
                let newArray = [...prevState, platform];
                return newArray;
            });
        }
    }

    function platformsContains(platform) {
        return selectedPlatforms.includes(platform);

    }

    function removePlatform(platform) {
        setSelectedPlatforms((prevState) => {
            let newArray = prevState.filter((v) => {
                return v !== platform;
            });
            return newArray;
        });
    }




    const validateEmail = (email) => {
        let valid = String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
        return valid;
    };




    function incrementActiveStep() {
        setActiveStep((prevState) => {
            return prevState + 1;
        });

    }
    function decrementActiveStep() {
        setActiveStep((prevState) => {
            return prevState - 1;
        });

    }
    useEffect(() => {
        window.scrollTo(0, 0);
      }, [activeStep]);


    function canProgress() {
        if (activeStep === 0) {
            return selectedProjectTypes.length > 0;
        }
        else if (activeStep === 1) {
            return selectedPlatforms.length > 0;
        }
        else if (activeStep == 2) {
            return (
                files.length > 0 ||
                projectDescription.length > 0
            )
        }
        else if (activeStep == 3) {
            return (
                senderName.length > 0 && senderEmail.length > 0 && validateEmail(senderEmail)
            )
        }

        else if (activeStep == 4) {
            return true;
        }
        else return false;
    }




    return (
        <>
            <SEO title="Custom Request" />
            <Layout style={{minHeight:"100vh"}}>

                <div className="main-content" >

                    {/* Start Request Submission Step Area style={{minHeight:"80vh"}} */}
                    <div className='row'>
                        <div className='col-12'>

                            <div className="rwt-contact-area rn-section-gap">
                                <div className="container">
                                    {
                                        false ? <AdditionalFeaturesStep

                                            add={addProjectType}
                                            remove={removeProjectType}
                                            contains={projectTypesContains}
                                        />
 
                                            /*error && activeStep == 4 ?
                                                <ErrorPage
                                                    errorText={errorText}
                                                />*/
                                            :

                                            activeStep == 0 ?
                                                <RequestStepOne

                                                    add={addProjectType}
                                                    remove={removeProjectType}
                                                    contains={projectTypesContains}

                                                />
                                                : activeStep == 1 ?
                                                    <RequestStepTwo

                                                        add={addPlatform}
                                                        remove={removePlatform}
                                                        contains={platformsContains}

                                                    /> :
                                                    activeStep == 2 ?
                                                        <RequestStepThree
                                                            files={files}
                                                            setFiles={setFiles}
                                                            setProjectDescription={setProjectDescription}
                                                            projectDescription={projectDescription}
                                                        />

                                                        : activeStep == 3 ?

                                                            <RequestStepFour
                                                                setSenderName={setSenderName}
                                                                setSenderEmail={setSenderEmail}
                                                                validateEmail={validateEmail}
                                                                senderEmail={senderEmail}
                                                                senderName={senderName}
                                                            />
                                                            : activeStep == 4 ?

                                                                <RequestStepFive
                                                                    senderName={senderName}
                                                                    senderEmail={senderEmail}
                                                                    selectedProjectTypes={selectedProjectTypes}
                                                                    selectedPlatforms={selectedPlatforms}
                                                                    projectDescription={projectDescription}

                                                                    files={files}
                                                                />
                                                                :
                                                                <ThankYou

                                                                />

                                    }
                                </div>


                            </div>

                        </div>

                    </div>

                    {/* End Request Submission Step Area  */}


                    {/* Next/Prev Step Bar */}

                    <div className='row mb--40'>
                        <div className='col-12 text-center'>

                            <StepBar
                                activeStep={activeStep}
                                canProgress={canProgress}
                                incrementActiveStep={incrementActiveStep}
                                decrementActiveStep={decrementActiveStep}
                                setRequestSubmitted={setRequestSubmitted}
                                requestSubmitted={requestSubmitted}
                                setSenderName={setSenderName}
                                setSenderEmail={setSenderEmail}
                                validateEmail={validateEmail}
                                senderName={senderName}
                                senderEmail={senderEmail}
                                selectedProjectTypes={selectedProjectTypes}
                                selectedPlatforms={selectedPlatforms}
                                projectDescription={projectDescription}
                                files={files}
                                setError={setError}
                                error={error}

                            />

                        </div>

                    </div>



                    {/* End Step Bar */}
                </div>
            </Layout>

        </>
    )
}
export default CustomRequest;