import React from 'react';
import ScrollAnimation from "react-animate-on-scroll";

const TimelineData = [
    {
        id: "1",
        date: "Step-1",
        title: "Tell us what you're looking to create.",
        description: "Let us know your goal - what you would like your indicator or strategy to do, how you want it to look, and any extra features you want it to incorporate.",
        image: "test-process-1.png",
        workingStep: [
            {
                stepTitle: "Tell us the details",
                stepDescription: "Fill us in on all the details of your envisioned trading tool. We always suggest additional features and improvements free of charge.",
            },
            {
                stepTitle: "Show us what you'd like",
                stepDescription: "Have an example of how you'd like your tools to look or perform? Show us. We'll be happy to replicate and improve upon it.",
            },
        ]
    },
    {
        id: "2",
        date: "Step-2",
        title: "We'll give you a price quote.",
        description: "Once we receive your request, we'll send you an estimate for the cost of developing it.",
        image: "test-process-2.png",
        workingStep: [
            {
                stepTitle: "Reasonable pricing",
                stepDescription: "Because of our expertise and experience in the field, we're able to beat our competitors' pricing while giving you the best possible result.",
            },
            {
                stepTitle: "Milestones",
                stepDescription: "If you accept the estimate, we charge 50% up front and the rest upon your approval of the completed project. Not satisfied? You will get a full refund.",
            },
        ]
    },
    {
        id: "3",
        date: "Step-3",
        title: "Once accepted, we'll continue until you're satisfied.",
        description: "Once the estimate is accepted, we will get to work on your custom project. We will continue to implement your feedback until the project meets your requirements and you are satisfied - or your money back.",
        image: "test-process-3.png",
        workingStep: [
            {
                stepTitle: "Feedback and revisions",
                stepDescription: "After receiving your completed project, there may be some changes you would like to make to the logic or styling. We will continue to revise your project until you are fully satisfied.",
            },
            {
                stepTitle: "Money-back guarantee",
                stepDescription: "Our clients are always satisfied with their final project. If you are unsatisfied with the final outcome of our development, you will receive a full refund.",
            },
        ]
    },
]

const TimelineOne = (props) => {
    return (
        <div className="rn-timeline-wrapper timeline-style-one position-relative">
            <div className="timeline-line"></div>
            {/* Start Single Timeline  */}

            {TimelineData.map((data, index) =>(
                <div className="single-timeline mt--50" key={index}>
                    <div className="timeline-dot">
                        <div className="time-line-circle"></div>
                    </div>
                    <div className="single-content">
                        <div className="inner">
                            <div className="row row--30 align-items-center">
                                <div className="order-2 order-lg-1 col-lg-6 mt_md--40 mt_sm--40">
                                    <div className="content">
                                        <ScrollAnimation 
                                        animateIn="fadeInUp"
                                        animateOut="fadeInOut"
                                        animateOnce={true}>
                                            <span className="date-of-timeline">{data.date}</span>
                                        </ScrollAnimation>

                                        <ScrollAnimation 
                                        animateIn="fadeInUp"
                                        animateOut="fadeInOut"
                                        animateOnce={true}>
                                            <h2 className="title">{data.title}</h2>
                                        </ScrollAnimation>

                                        <ScrollAnimation 
                                        animateIn="fadeInUp"
                                        animateOut="fadeInOut"
                                        animateOnce={true}>
                                            <p className="description">{data.description}</p>
                                        </ScrollAnimation>

                                        <div className="row row--30">
                                            {data.workingStep.map((data, index)=>(
                                                <div className="col-lg-6 col-md-6 col-12" key={index}>
                                                    <div className="working-list">
                                                        <ScrollAnimation 
                                                        animateIn="fadeInUp"
                                                        animateOut="fadeInOut"
                                                        animateOnce={true}>
                                                            <h5 className="working-title">{data.stepTitle}</h5>
                                                        </ScrollAnimation>

                                                        <ScrollAnimation 
                                                        animateIn="fadeInUp"
                                                        animateOut="fadeInOut"
                                                        animateOnce={true}>
                                                            <p>{data.stepDescription}</p>
                                                        </ScrollAnimation>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                        
                                        <div className="read-morebtn">
                                            <ScrollAnimation 
                                            animateIn="fadeInUp"
                                            animateOut="fadeInOut"
                                            animateOnce={true}>
                                                <a className="btn-default btn-large round" onClick={props.goToSubmitForm}  style={{ cursor: "pointer" }}><span>{index == 0 ? 'Tell us your goals' :
                                                index == 1 ? "Submit a request" : "Let's go"}</span></a>
                                            </ScrollAnimation>
                                        </div>
                                    </div>
                                </div>
                                <div className="order-1 order-lg-2 col-lg-6">
                                    <div className="thumbnail">
                                        <img className="w-100" src={`./images/timeline/${data.image}`} alt="Corporate React Template" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
           
            {/* End Single Timeline  */}
        </div>
    )
}

export default TimelineOne;
