import React from 'react';
import ContactForm from "./ContactForm";
import GoogleMapStyle from "./GoogleMapStyle";
import { FiHeadphones, FiMail, FiMapPin } from "react-icons/fi";
import SelectableCard from './SelectableCard';
import SectionTitle from '../sectionTitle/SectionTitle';
import CalltoActionSix from '../calltoaction/CalltoActionSix';
import StandardDropzone from './StandardDropzone';

const RequestStepFour = (props) => {

    

    function handleNameEntry(event) {
        let input = event.target.value.toString();
        props.setSenderName(input);
    }

    function handleEmailEntry(event) {
        let input = event.target.value.toString();
        props.setSenderEmail(input);
    }


    return (
        <>
            <div className="row row--15">
                <div className="col-lg-12">
                    <div className="rn-contact-address mt_dec--30">
                        <div className="row">


                            <div className="col-lg-12 mt--10">
                                <SectionTitle
                                    textAlign="text-left"
                                    radiusRounded=""
                                    subtitle="Step 4"
                                    title="Your contact details."
                                />

                                <p className="text-left">Let us know how we can contact you about your request.</p>

                            </div>




                            <form className="contact-form-1">
                                <div className="form-group">
                                    <input
                                        value={props.senderName}
                                        type="text"
                                        name="fullname"
                                        placeholder="Your Name"
                                        required
                                        onInput={handleNameEntry}
                                        className={`${props.senderName.length > 0 ? '' : 'selected-card-border'} `}

                                    />
                                </div>

                                <div className="form-group">
                                    <input
                                        value={props.senderEmail}
                                        type="email"
                                        name="email"
                                        placeholder="Email Address"
                                        required
                                        onInput={handleEmailEntry}
                                        className={`${props.validateEmail(props.senderEmail) ? '' : 'selected-card-border'} `}

                                    />
                                </div>
                            </form>

                        </div>



                    </div>
                </div>
            </div>

            <div className="row mt--40 row--15">
                <div className="col-lg-12">
                </div>

            </div>
        </>
    )
}
export default RequestStepFour;