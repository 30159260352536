import React from 'react';
import { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import SEO from "../common/SEO";
import { Link } from "react-router-dom";
import { FiArrowRight, FiCheck } from "react-icons/fi";
import HeaderOne from '../common/header/HeaderOne';
import Copyright from '../common/footer/Copyright';
import ScrollTop from '../common/footer/ScrollTop';
import GalleryPortfolio from '../elements/portfolio/GalleryPortfolio';
import BrandThree from '../elements/brand/BrandThree';
import CounterUpOne from "../elements/counterup/CounterUpOne";
import AboutFour from '../elements/about/AboutFour';
import SlipThree from '../elements/split/SlipThree';
import { Widget, addResponseMessage, setQuickButtons } from 'react-chat-widget';
import 'react-chat-widget/lib/styles.css';
import Separator from "../elements/separator/Separator";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import PortfolioOne from "../elements/portfolio/PortfolioOne";
import Social from '../elements/social/Social';
import TimelineOne from "../elements/timeline/TimelineOne";
import TestimonialThree from "../elements/testimonial/TestimonialThree";
import GalleryOne from "../elements/gallery/GalleryOne";
import AdvanceTab from "../elements/advancetab/AdvanceTab";
import AdvanceTabOne from "../elements/advancetab/AdvanceTabOne";
import './video.css';
import axios from 'axios';
import Logo from "../assets/images/logo/tc2.png"
import HelpAgent from "../assets/images/chat.png"

//import io from 'socket.io-client';
import NewsletterOne from '../common/footer/NewsletterOne';
//const socket = io.connect('http://localhost:5000');


const CreativePortfolio = () => {
    let navigate = useNavigate();


    const scrollToProcess = () => {
        const element = document.getElementById("OurProcess")

        element.scrollIntoView({ behavior: "smooth" })
    }
    // const handleNewUserMessage = (newMessage) => {
    //     console.log("My socket: " + socket.id);
    //     /*
    //     // Now send the message through the backend API
    //     var request = new XMLHttpRequest();
    //     request.open("POST", "https://discord.com/api/webhooks/1009607077557194772/r7X7PObkNWwGqh9rV0_3Dc1MFDGICe4akft0UFTuDpxi0lNAqm-0bDcidl0prRkrVBgO");
  
    //     request.setRequestHeader('Content-type', 'application/json');
  
    //     var params = {
    //       username: "My Webhook Name",
    //       avatar_url: "",
    //       content: newMessage
    //     }
  
    //     request.send(JSON.stringify(params));
    //     */

    //     // Websocket communications
    //     socket.emit("send_message",
    //         {
    //             message: newMessage
    //         }
    //     );

    // };

    // // Message receive effect
    // useEffect(() => {
    //     console.log("UE");
    //     socket.on("receive_message", (data) => {
    //         console.log("Received message: " + data.message);
    //         addResponseMessage(data.message);
    //     });
    // }, [socket])

    // useEffect(() => {
    //     console.log("UE");
    //     socket.on("receive_message", (data) => {
    //         console.log("Received message: " + data.message);
    //         addResponseMessage(data.message);
    //     });
    // }, [socket])


    const goToSubmitForm = () => {

        window.scrollTo(0, 0);
        navigate("/CustomRequest");

    };

    const [darkMode, setDarkMode] = useState(!document.querySelector('body').classList.contains("light"));

    return (
        <>
            <SEO title="Traders Custom" />
            <main className="page-wrapper">


                <HeaderOne
                    btnStyle="btn-small round btn-icon"
                    HeaderSTyle="header-not-transparent"
                    setDarkMode={setDarkMode}
                />



                {/* Start Slider area  */}
                <div className="slider-area slider-style-7 height-750">

                    <div className="video-background" style={{ position: "absolute", opacity: "40%" }}> {/*14 if dark mode, 100 if light*/}
                        <video
                            autoPlay
                            loop
                            muted

                            src={darkMode ? './images/videos/video-dark-bg.mp4' : './images/videos/video-light-bg.mp4'}//"https://www.youtube.com/embed/8EPMNHeBAAI?autoplay=1&controls=0&mute=1&loop=1&modestbranding=1&showinfo=0&start=83&enablejsapi=1&&widgetid=3"
                            title="YouTube video player"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" />
                    </div>

                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                {/*  <Slider dots infinite autoplaySpeed={10000} fade autoplay className="slider-area slider-style-3 slider-dot rn-slick-dot rn-slick-arrow">
                                    <div key={0} className="single-slide">*/}
                                <div className="inner text-center">
                                    <h4 class="subtitle rn-sub-badge"><span class="theme-gradient">The best custom indicators and strategies, period.</span></h4>
                                    <h1 className="title">Turn your ideas into indicators and strategies.</h1>
                                    <div className="button-group mt--40 mt_sm--20">
                                        <a className="btn-default round btn-icon btn-extra-large" target="_blank" onClick={goToSubmitForm} style={{ cursor: "pointer" }}>Submit a Request<i className="icon"><FiArrowRight /></i></a>
                                        <a className="btn-default round btn-icon btn-extra-large" target="_blank" onClick={scrollToProcess} style={{ cursor: "pointer" }}>See our process<i className="icon"><FiArrowRight /></i></a>
                                    </div>
                                </div>
                                {/*  <div key={1} className="single-slide">

                                        <div className="inner text-center">

                                            <h4 class="subtitle rn-sub-badge"><span class="theme-gradient">Consistent profits. Verifiable results.</span></h4>
                                            <h1 className="title">Dark Wave ETH is up another 124% this month.</h1>
                                            <h3 className="description"><span style={{ color: "white" }}>The most profitable and proven daytrading strategy for ETH.</span></h3>

                                            <div className="button-group mt--40 mt_sm--20">
                                                <a className="btn-default round btn-icon btn-extra-large" target="_blank" onClick={goToSubmitForm} style={{ cursor: "pointer" }}>Buy now<i className="icon"><FiArrowRight /></i></a>
                                                <a className="btn-default round btn-icon btn-extra-large" target="_blank" onClick={scrollToProcess} style={{ cursor: "pointer" }}>Watch it trading live<i className="icon"><FiArrowRight /></i></a>
                                            </div>
                                        </div>
                                    </div>

                                </Slider>
                                */}

                            </div>
                        </div>
                    </div>
                </div>
                {/* End Slider area  */}

                {/* Start Brand Area  */}
                <div className="rwt-brand-area ptb--90">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 mt--10">
                                <SectionTitle
                                    textAlign="text-center"
                                    radiusRounded=""
                                    subtitle="Top Supported platforms"
                                />
                                <BrandThree brandStyle="brand-style-2" />
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Brand Area  */}
                <Separator />


                {/* Start Elements Area  */}
                <div className="rwt-advance-tab-area rn-section-gap">
                    <div className="container">
                    <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign="text-center"
                                    radiusRounded=""
                                    subtitle="Our specialties"
                                    title="What can we do for you?"
                                />
                            </div>
                        </div>
                        <div className="row mb--40">
                            <div className="col-lg-12">

                            </div>
                        </div>
                        <AdvanceTabOne />
                    </div>
                </div>
                {/* End Elements Area  */}


                {/* Start Timeline Area  */}
                <div className="rwt-timeline-area rn-section-gap" id="OurProcess">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign="text-center"
                                    radiusRounded=""
                                    subtitle="The steps to our process"
                                    title="The Development Process"
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-lg-12">
                                <TimelineOne goToSubmitForm={goToSubmitForm} />
                            </div>
                        </div>


                    </div>

                </div>
                {/* End Timeline Area  */}




                <Separator />
                {/* Start Counter Up Area  */}
                <div className="rwt-counterup-area rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign="text-center"
                                    radiusRounded=""
                                    subtitle="Our track record"
                                    title="You're in good hands."
                                    description="We have developed thousands of successful indicators and strategies for satisfied clients all around the world."

                                />
                            </div>
                        </div>
                        <CounterUpOne column="col-lg-4 col-md-12 col-sm-12 col-12" counterStyle="counter-style-1" textALign="text-center" />
                    </div>
                </div>
                {/* End Counter Up Area  */}

                {/* Start Testimonials Area */}

                <div className="rwt-elements-area rn-section-gap">
                    <div className="container">
                        <div className="row mb--20">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign="text-center"
                                    radiusRounded=""
                                    subtitle="What our clients are saying"
                                />
                            </div>
                        </div>
                        <TestimonialThree teamStyle="" />
                    </div>
                </div>
                {/* End Testimonials Area */}

                <Separator />


{/* 

                <div className="service-area rn-section-gapTop">
                    <div className="container">
                        <div className="row">
                            <NewsletterOne newsletetrStyle="rn-newsletter-default" extraClass="border-top-bottom" />

                        </div>
                    </div>
                </div> */}
                {/* End project examples area */}
                {/*
                <Widget
                profileAvatar={Logo}
                emojis
                resizable
                title="Live Help Chat"
                subtitle={false}
                handleNewUserMessage={handleNewUserMessage}
            />
*/}


                <Copyright />

                <ScrollTop />
            </main>



        </>
    )
}
export default CreativePortfolio;