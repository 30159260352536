import React from 'react';
import ContactForm from "./ContactForm";
import GoogleMapStyle from "./GoogleMapStyle";
import { FiHeadphones, FiMail, FiMapPin } from "react-icons/fi";
import SelectableCard from './SelectableCard';
import SectionTitle from '../sectionTitle/SectionTitle';
import CalltoActionSix from '../calltoaction/CalltoActionSix';

const RequestStepOne = (props) => {
    return (
        <>
            <div className="row row--15">
                <div className="col-lg-12">
                    <div className="rn-contact-address mt_dec--30">
                        <div className="row">


                            <div className="col-lg-12 mt--10">
                                <SectionTitle
                                    textAlign="text-left"
                                    radiusRounded=""
                                    subtitle="Step 1"
                                    title="What does your request include?"
                                />

                                <p className="text-left">Please select the project type(s) that apply to your request.</p>

                            </div>


                            <SelectableCard
                                add={props.add}
                                remove={props.remove}
                                contains={props.contains}
                                title="Custom Indicators"
                                image={`./images/custom/area-graph.png`}
                                //image={`https://jthemes.net/themes/html/neonwizard-react/v1/assets/img/d1.png`}
                            />
                            <SelectableCard
                                add={props.add}
                                remove={props.remove}
                                contains={props.contains}

                                title="Custom Strategies"
                                image={`./images/custom/diagram.png`}

                                //image={`https://jthemes.net/themes/html/neonwizard-react/v1/assets/img/d2.png`}

                            />
                            <SelectableCard
                                add={props.add}
                                remove={props.remove}
                                contains={props.contains}

                                title="Platform Conversions"
                                image={`./images/custom/infographics (1).png`}

                                //image={`https://jthemes.net/themes/html/neonwizard-react/v1/assets/img/d3.png`}

                            />

                        </div>


                     
                    </div>
                </div>
            </div>

            <div className="row mt--40 row--15">
                <div className="col-lg-12">
                </div>

            </div>
        </>
    )
}
export default RequestStepOne;