import React from 'react';
import ContactForm from "./ContactForm";
import GoogleMapStyle from "./GoogleMapStyle";
import { FiHeadphones, FiMail, FiMapPin } from "react-icons/fi";
import SelectableCard from './SelectableCard';
import SectionTitle from '../sectionTitle/SectionTitle';
import CalltoActionSix from '../calltoaction/CalltoActionSix';
import StandardDropzone from './StandardDropzone';
import Separator from "../separator/Separator";
const RequestStepFive = (props) => {

    return (
        <>
            <div className="row row--15">
                <div className="col-lg-12">
                    <div className="rn-contact-address mt_dec--30">
                        <div className="row">


                            <div className="col-lg-12 mt--10">
                                <SectionTitle
                                    textAlign="text-left"
                                    radiusRounded=""
                                    subtitle="Got everything?"
                                    title="Review your request."
                                />

                                <p className="text-left">Review your request to make sure you've included everything you'd like us to know. Be sure to confirm that your email address is correct so that we can reach out to you.</p>

                            </div>


                        </div>
                        <Separator />
                        <div className="row row--15 mt--40">
                            <div className="col-lg-12">

                                <div className='row'>
                                    <div className='col-lg-6 col-md-6 col-sm-6 col-12'>
                                        <h5>Name</h5>
                                        <p>{props.senderName}</p>
                                    </div>
                                    <div className='col-lg-6 col-md-6 col-sm-6 col-12'>
                                        <h5>Email Address</h5>
                                        <p>{props.senderEmail}</p>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col-lg-6 col-md-6 col-sm-6 col-12'>
                                        <h5>Project Type</h5>
                                        <ul>
                                            {props.selectedProjectTypes.map((type) =>
                                                <li><p>{type}</p></li>
                                            )}
                                        </ul>
                                    </div>
                                    <div className='col-lg-6 col-md-6 col-sm-6 col-12'>
                                        <h5>Selected Platforms</h5>
                                        <ul>
                                            {props.selectedPlatforms.map((platform) =>
                                                <li><p>{platform}</p></li>
                                            )}
                                        </ul>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col-12'>
                                        <h5>Project Description</h5>
                                        <p>{props.projectDescription}</p>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col-12'>
                                        <h5>Uploaded Files</h5>
                                        {props.files.length > 0 ?
                                            <div className='row'>
                                                {
                                                    props.files.map((file) =>
                                                        <div className="col-4">
                                                            <ul>
                                                                <li><p>{file.name}</p></li>
                                                            </ul>

                                                        </div>

                                                    )
                                                }
                                            </div>
                                            : <p>None</p>
                                        }
                                    </div>
                                </div>



                                {/*
                                senderName={senderName}
                                                        senderEmail={senderEmail}
                                                        selectedProjectTypes={selectedProjectTypes}
                                                        selectedPlatforms={selectedPlatforms}
                                                        projectDescription={projectDescription}
    files={files} */}
                            </div>
                        </div>


                    </div>
                </div>
            </div>


        </>
    )
}
export default RequestStepFive;