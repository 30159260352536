import React from 'react';
import Slider from "react-slick";
import { slickDot } from "../../utils/script";
const TestimonialData = [
    {
        form: "United States",
        description: "Another job well done and thanks again for the complimentary strategy version of the indicator. Can't say how much I appreciate it and I really respect your work. I'll do some deep backtesting on TradingView and see if I can come up with any changes I want for the next version.",
        name: "Joshua Teller",
        subtitle: "Crypto Trader",
        image: "testimonial-dark-03"
    },
    {
        form: "United States",
        description: "EVERY OTHER DEVELOPER claimed that this wasn't even possible on TradingView. The use of arrays was extremely clever, you've gained a lifetime customer. Thanks a lot, and I will be building off of your source code if that is ok...",
        name: "Farhad Ahmadi",
        subtitle: "Developer and Trader",
        image: "testimonial-dark-01"
    },


    {
        form: "United States",
        description: "First of all, amazing job. Really some great programming because it looks great and works great with all of these options. I've passed it on to my key students as part of my mentoring package and it has added a lot of value. No more manually drawing the patterns... Thanks again.",
        name: "Brandon Bellware",
        subtitle: "Lead Instructor - Trader Bunny",
        image: "testimonial-dark-01"
    },
    {
        form: "Germany",
        description: "I'm glad it could be done and grateful for the extra aesthetic features/options/etc. I'll definitely be back for a new project shortly.",
        name: "Joel Werner",
        subtitle: "Equity Trader",
        image: "testimonial-dark-02"
    }
   
]

const TestimonialThree = ({teamStyle}) => {
    return (
        <div className="row">
            <div className="col-lg-12">
                <Slider className="slick-space-gutter--15 rn-slick-dot rn-slick-arrow mb--60" {...slickDot}>
                    {TestimonialData.map((data, index) => (
                        <div key={index} className={`testimonial-style-two ${teamStyle}`}>
                            <div className="row align-items-center row--20">
                                <div className="order-2 order-md-1 col-lg-10 col-md-10 offset-lg-1">
                                    <div className="content mt_sm--40">
                                        <span className="form">{data.form}</span>
                                        <p className="description">{data.description}</p>
                                        <div className="client-info">
                                            <h4 className="title">{data.name}</h4>
                                            <h6 className="subtitle">{data.subtitle}</h6>
                                        </div>
                                    </div>
                                </div>
                               
                            </div> 
                        </div>
                    ))}
                </Slider>
            </div>
        </div>
    )
}
export default TestimonialThree;




















