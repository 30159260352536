import React from 'react';

const BrandList = [
    {
        image: './images/brand/tv-color.png',
        link: "https://www.TradingView.com"
    },
    {
        image: './images/brand/nt-color.png',
        link: "https://www.NinjaTrader.com"
    },
 
    {
        image: './images/brand/mt-color.png',
        link: "https://www.MetaTrader.com"
    },
    {
        image: './images/brand/tos-color.png',
        link: "https://www.ThinkOrSwim.com"
    },
    {
        image: './images/brand/ts-color.png',
        link: "https://www.TradeStation.com"

    }

  
]

const BrandThree = ({brandStyle}) => {
    return (
        <ul className={`brand-list ${brandStyle}`}>
            {BrandList.map((data, index) => (
                <li key={index}>
                    <a href={data.link} target="_blank"><img src={`${data.image}`} alt="Brand Image" /></a>
                </li>
            ))}
        </ul>
    )
}

export default BrandThree;
