import React from 'react';
import { useState } from 'react';
import { Radio } from 'react-bootstrap';
import { FiCheck, FiMail, FiMapPin } from "react-icons/fi";

const SelectableCard = (props) => {
    const [selected, setSelected] = useState(false);

    const select = () => {
        if (props.contains(props.title))
            props.remove(props.title);
        else
            props.add(props.title);


    }

    return (


        <div className="col-lg-4 col-md-12 col-12">
            <div style={{ cursor: "pointer" }} onClick={select} >

                <div className={`${props.contains(props.title) ? 'project-type-card selected-card-border' : 'project-type-card deselected-card-border '}`}>



                    <div className="inner" >
                        <div className='row'>

                            <div className="col-10">

                            </div>
                            <div className="col-2">
                                <div className={`${props.contains(props.title) ? 'circle common-gradient-background' : 'circle'}`} />

                            </div>


                        </div>
                        <div className="container" style={{ justifyContent: "center", alignItems: "center", display: "flex" }}>
                            <img className="" src={props.image} style={{maxHeight:"100px"}} />


                        </div>

                        <h4 className="title text-center mt--30 common-gradient-color">{props.title}</h4>

                    </div>
                </div>
            </div>
        </div>

    );
}

export default SelectableCard;

