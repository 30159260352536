import React from 'react'
import SEO from "../common/SEO";
import HeaderTopNews from '../common/header/HeaderTopNews';
import HeaderOne from '../common/header/HeaderOne';
import FooterFour from '../common/footer/FooterFour';
import BrandThree from '../elements/brand/BrandThree';
import AboutFour from '../elements/about/AboutFour';
import ServiceOne from "../elements/service/ServiceOne";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import CounterUpFour from "../elements/counterup/CounterUpFour";
import TeamFour from "../elements/team/TeamFour";
import TimelineTwo from "../elements/timeline/TimelineTwo";
import Separator from "../elements/separator/Separator";
import Copyright from '../common/footer/Copyright';
const Disclaimer = () => {
    return (
        <>
            <SEO title="Disclaimer" />
            <main className="page-wrapper">
                <HeaderOne btnStyle="btn-small round btn-icon" HeaderSTyle="header-transparent" />


                {/* Start Service Area  */}
                <div className="service-area rn-section-gapTop">
                    <div className="container">
                        <h2 className="title">
                            Disclaimer
                        </h2>
                        <h3 className="title">
                            INFORMATION PROVIDED IN THIS DISCLAIMER IS FOR GENERAL INFORMATION PURPOSES AND NOT MEANT AS SPECIFIC ADVICE FOR YOUR INDIVIDUAL CIRCUMSTANCES OR AS PERSONALIZED INVESTMENT ADVICE.
                        </h3>

                        <p className="description">
                            By acknowledging this statement and contracting for the services and products provided by Traders Custom, (“Traders Custom”, “Company” “we”, or “our”), you acknowledge that you have not sought advice from Traders Custom regarding the appropriateness of trading for you, and Traders Custom has not provided any such advice to you.
                        </p>

                        <h3 className="title">
                            RISKS ASSOCIATED WITH TRADING
                        </h3>
                        <p className="description">

                            Trading with financial instruments or cryptocurrencies carries a high level of risk, and may not be suitable for all investors and use of our website traderscustom.com (“website” or “platform”) which allows access to information about trading strategies, analysis and use of certain software is not appropriate for all investors, and may carry a high level of risk to your investment.  Before deciding to trade with financial instruments or cryptocurrencies you should carefully consider your investment objectives, level of experience, and risk appetite. The possibility exists that you could sustain a loss in excess of your initial investment.

                            There is considerable exposure to risk in any financial instruments transaction. Any transaction involving currencies and financial instruments involves risks including, but not limited to, the potential for changing political and/or economic conditions that may substantially affect the price or liquidity of such currency or financial instrument. This means that any market movement will have a proportional effect on your deposited funds. This may work against you as well as for you. Not only may investors get back less than they invested, but in the case of higher risk strategies, investors may lose the entirety of their investment. It is for this reason that when speculating in such markets it is generally recommended to use only risk capital or, in other words, only use money you can afford to lose.

                            You should be aware of all the risks associated with financial instruments transactions before becoming our client and seek investment, financial, tax or other advice in advance if necessary.
                        </p>
                        <h5 className="title">
                            THIS BRIEF STATEMENT CANNOT DISCLOSE ALL THE RISKS AND OTHER ASPECTS OF THE CRYPTOCURRENCY OR FINANCIAL MARKETS. YOU SHOULD CONSULT WITH YOUR FINANCIAL ADVISOR FOR DETAILED INFORMATION.
                        </h5>
                        <h3 className="title">
                            CUSTOM SOFTWARE, PROGRAMS, INDICATORS, STRATEGIES, OR SCRIPTS DEVELOPED BY TRADERS CUSTOM
                        </h3>

                        <p className='description'>

                            ALL SOFTWARE DEVELOPED BY Traders Custom IS PROVIDED “AS IS”, WITHOUT WARRANTY OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM, DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE USE OR OTHER DEALINGS IN THE SOFTWARE. IF SOFTWARE DEVELOPED FOR YOU BY Traders Custom DOES NOT PERFORM AS EXPECTED, IT IS SOLELY YOUR DUTY AND RESPONSIBILITY TO ASK Traders Custom TO AMEND THE ISSUE BEFORE THE PROJECT IS FINALIZED.
                        </p>

                        <h3 className="title">
                            ANALYSES, RESEARCH, OR SIGNALS PROVIDED BY TRADERS CUSTOM
                        </h3>
                        <p className="description">

                            Any opinions, news, research, analyses, prices or other information contained on this website is provided as general market commentary and does not constitute investment advice or investment services as per the applicable legislation. Traders Custom will not accept liability for any loss or damage including, without limitation to, any loss of profit which may arise directly or indirectly from use of or reliance on such information.

                            No information or opinion contained on the website should be taken as a solicitation or offer to buy or sell any currency, equity or other financial instruments or services.

                            Any product purchased from Traders Custom should be carefully considered by you and a financial professional. Automated trading programs carry a particularly high risk due to their ungoverned nature. Any losses or gains made with one of these programs is your own responsibility. Traders Custom takes no responsibility for any losses incurred or any malfunctions of the software on your account. Money lost by a program created or recommended by Traders Custom will not be refunded, as we are not liable for the transactions made in your accounts.

                            You further agree and acknowledge that the trading signals provided to you by Traders Custom are not, and are not intended to be, an offer or solicitation to buy or sell any currency, equity or other financial instruments or services, or any type of trading or investment advice, recommendation or strategy. You acknowledge that it is solely your decision to determine which, if any, Traders Custom trading signals to use for trading (whether actual or simulated). At no point will any trading signals provided by Traders Custom be individual to you or your particular circumstances, nor will they be personalized to you.

                            You understand that past performance associated with any trading signals offered by Traders Custom, whether actual, hypothetical or historically “back-tested” is neither necessarily indicative of nor a guarantee of future performance or success.

                            Traders Custom signals may result in the total loss of the funds that you deposit at a licensed investment intermediary, and you may incur losses in excess of your deposits. Leverage can work against you as well as for you.

                            Based on the foregoing, you agree that you shall not seek to hold us responsible for any losses associated with any trading signals provided to you by us. In the event that any claims, suits, actions, damages, liabilities, obligations, losses, or expenses (including reasonable attorneys’ fees) arise out of or relate to the relationship between us and you, and/or any signals provided by us to you, you agree to indemnify and hold us harmless therefrom.

                            Traders Custom does not hold itself out as a commodity trading advisor. All information and material provided by us is for educational purposes only and should not be considered specific investment advice. Traders Custom is not providing this information as advice, nor are we providing this information based on or tailored to your specific circumstance or trading activity. The information that we provide or that is derived from our website should not be a substitute for advice from an investment professional.
                        </p>



                        <h3 className="title">
                            ACCURACY OF INFORMATION
                        </h3>
                        <p className="description">

                            The content on this website is subject to change at any time without notice, and is not provided for the sole purpose of assisting traders to make independent investment decisions. Traders Custom has taken reasonable measures to ensure the accuracy of the information on the website; however, it does not guarantee accuracy and will not accept liability for any loss or damage which may arise directly or indirectly from the content or from your inability to access the website, or for any delay in or failure of the transmission or the receipt of any instruction or notifications sent through this website.
                        </p>
                        <h3 className="title">
                            Hypothetical Results Disclaimer
                        </h3>
                        <p className="description">

                            THE RESULTS PROVIDED BY Traders Custom MAY BE BASED ON SIMULATED OR HYPOTHETICAL PERFORMANCE RESULTS THAT HAVE CERTAIN INHERENT LIMITATIONS. UNLIKE THE RESULTS SHOWN IN AN ACTUAL PERFORMANCE RECORD, THESE RESULTS DO NOT REPRESENT ACTUAL TRADING. ALSO, BECAUSE THESE TRADES HAVE NOT ACTUALLY BEEN EXECUTED, THESE RESULTS MAY HAVE UNDER-OR OVER-COMPENSATED FOR THE IMPACT, IF ANY, OF CERTAIN MARKET FACTORS, SUCH AS LACK OF LIQUIDITY. SIMULATED OR HYPOTHETICAL TRADING PROGRAMS IN GENERAL ARE ALSO SUBJECT TO THE FACT THAT THEY ARE DESIGNED WITH THE BENEFIT OF HINDSIGHT. NO REPRESENTATION IS BEING MADE THAT ANY ACCOUNT WILL OR IS LIKELY TO ACHIEVE PROFITS OR LOSSES SIMILAR TO THESE BEING SHOWN.

                            The information that may be presented is based on simulated trading using systems and education developed exclusively by Traders Custom and do not represent actual trading. Please note that simulated trading results may or may not have been back-tested for accuracy and that spreads/commissions are not taken into account when preparing hypothetical results.

                            No representation is being made that any account will or is likely to achieve profits or losses similar to those that may be shown. Past performance is not indicative of future results. Individual results may vary, and no representation is made that clients will or are likely to achieve profits or incur losses comparable to those that may be shown.
                        </p>
                        <h3 className="title">
                            Acknowledgement and Agreement
                        </h3>
                        <p className="description">

                            By using the products or services offered by us, or using this website you agree that we shall not be held liable for any direct, indirect, consequential loss or any damages whatsoever arising from this usage, or the use of any information, signals, messages, education, and any other information contained or disseminated in regard to its use and understanding. You use this website and the products or services offered by us at your own risk. Neither a guarantee of performance results nor any anticipated return on investment is offered at any time. Past performance is no guarantee of future results.

                            By using the website and other services provided, you agree not to hold us, or any of our affiliates, liable for decisions that are based on information contained in blog posts, reader responses to blog posts, or information anywhere else on the website or in promotional material.
                        </p>
                        <h3 className="title">
                            Third Party
                        </h3>
                        <p className="description">

                            Any links provided to other websites are offered as a matter of convenience and in no way are meant to imply that Traders Custom endorses, sponsors, promotes or is affiliated with the owners of or participants in those sites, or endorses or warrants any information contained on those sites, unless expressly stated. Furthermore, Traders Custom does not endorse, guarantee or warranty any third party products advertised on its site, in its promotional materials, or otherwise referenced in any information provided by us or derived from the website, and Traders Custom expressly disclaims any responsibility and accepts no liability with respect to such information and products. Traders Custom may receive a fee from a service provider that has a link or advertisement on the website or is referenced in the information provided by us.
                        </p>
                        <h3 className="title">
                            Distribution
                        </h3>
                        <p className="description">

                            This site is not intended for distribution or use by any person in any country where such distribution or use would be contrary to local law or regulation. None of the services referred to in this website are available to persons residing in any country where the provision of such services would be contrary to local law or regulation. It is the responsibility of visitors to this website to ascertain the terms of and comply with any local law or regulation to which they are subject.
                        </p>
                    </div>
                </div>
                {/* End Service Area  */}


            </main>
            <Copyright />
        </>
    )
}

export default Disclaimer;
