import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { useState } from 'react';

const AdvanceTabOne = () => {

    const [tabNum, setTabNum] = useState(0);
    return (
        <div>
            <Tabs forceRenderTabPanel>
                <div className="row row--30" style={{alignItems:"center", justifyContent:"center", alignContent:"center"}}>
                    <div className="order-2 order-lg-1 col-lg-4 col-md-12 col-sm-12 col-12 mt_md--30 mt_sm--30">
                        <div className="advance-tab-button advance-tab-button-1">
                            <TabList className="tab-button-list">
                                <Tab onClick={() => setTabNum(0)}>
                                    <div className="tab-button">
                                        <h4 className="title">Custom Indicators</h4>
                                        <p className="description">Bring your indicator ideas to life with innovative, <strong>next level</strong> visuals and logic. We'll push the platform to its limits. Get what you asked for - <strong>and more</strong>.</p>
                                    </div>
                                </Tab>
                                <Tab onClick={() => setTabNum(1)}>
                                    <div className="tab-button">
                                        <h4 className="title">Custom Strategies</h4>
                                        <p className="description">With <strong>10+ years</strong> of experience in developing, optimizing, and consulting on automated trading strategies, <strong>we know exactly what we're doing</strong>. Let us execute on your great idea.</p>
                                    </div>
                                </Tab>
                                <Tab onClick={() => setTabNum(2)}>
                                    <div className="tab-button">
                                        <h4 className="title">Platform Conversions</h4>
                                        <p className="description"><strong>Unparallelled</strong> experience in platform-to-platform conversions, with over 500 completed for satisfied clients. Get conversions done <strong>fast</strong> and <strong>right</strong>.</p>
                                    </div>
                                </Tab>
                            </TabList>
                        </div>
                    </div>
                    {/*
 <TabList className="tab-button-list">
                                <Tab onClick={() => setTabNum(0)}>
                                    <div className="tab-button">
                                        <h4 className="title">Indicators</h4>
                                        <p className="description">We push platforms to their limits to make the <strong>best</strong> indicators on the market.<br/><br/>We innovate to bring your logic and visuals to the next level.</p>
                                    </div>
                                </Tab>
                                <Tab onClick={() => setTabNum(1)}>
                                    <div className="tab-button">
                                        <h4 className="title">Strategies</h4>
                                        <p className="description">With over 10 years of experience in developing, backtesting, optimizing and consulting on automated trading strategies, we know exactly what we're doing.<br/><br/>We will let you execute on your dream trading strategy.</p>
                                    </div>
                                </Tab>
                                <Tab onClick={() => setTabNum(2)}>
                                    <div className="tab-button">
                                        <h4 className="title">Platform Conversions</h4>
                                        <p className="description">We have unparallelled experience developing for all of the popular trading platforms on the market. <br/><br/>We have completed over 500 platform-to-platform conversions for satisfied clients.</p>
                                    </div>
                                </Tab>
                            </TabList>
    */}
                    <div className="order-1 order-lg-2 col-lg-8 col-md-12 col-sm-12 col-12" style={{alignItems:"center", justifyContent:"center", alignContent:"center"}}>
                        <div className="advance-tab-content advance-tab-content-1 ">
                            {

                                <div className="tab-content" >
                                    <div className="inner">
                                        <div className="thumbnail">
                                            <img src={`./images/tab/tabs-0${tabNum + 1}.png`} alt="Corporate Images" />
                                        </div>
                                    </div>
                                </div>
                            }


                            <TabPanel>

                            </TabPanel>

                            <TabPanel>

                            </TabPanel>

                            <TabPanel>

                            </TabPanel>
                        </div>
                    </div>
                </div>
            </Tabs>
        </div>
    )
}
export default AdvanceTabOne;