import React from 'react'
import SEO from "../common/SEO";
import HeaderTopNews from '../common/header/HeaderTopNews';
import HeaderOne from '../common/header/HeaderOne';
import FooterFour from '../common/footer/FooterFour';
import BrandThree from '../elements/brand/BrandThree';
import AboutFour from '../elements/about/AboutFour';
import AccordionOne from "../elements/accordion/AccordionOne";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import CounterUpFour from "../elements/counterup/CounterUpFour";
import TeamFour from "../elements/team/TeamFour";
import TimelineTwo from "../elements/timeline/TimelineTwo";
import Separator from "../elements/separator/Separator";
import NewsletterOne from '../common/footer/NewsletterOne';
import Copyright from "../common/footer/Copyright";
import { Link } from "react-router-dom";
import ContactForm from '../elements/contact/ContactForm';

import { FiChevronRight, FiArrowRight } from 'react-icons/fi';
const ContactUs = () => {
    return (
        <>
            <SEO title="Contact Us" />
            <HeaderOne btnStyle="btn-small round btn-icon" />

            <main className="page-wrapper align-contents-center justify-content-center text-center">
            <div className="rwt-brand-area ptb--90">
                    <div className="container">
                    <div className="row">
                            <div className="col-lg-12 mt--10">
                             <h3>Special inquiry or question? Send us a message.</h3>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12 mt--10">
                             <ContactForm/>
                            </div>
                        </div>
                    </div>
                </div>
           </main>
            <Copyright />

        </>
    )
}

export default ContactUs;
