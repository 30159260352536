import React from 'react';
import { useDropzone } from 'react-dropzone'
import { useCallback, useMemo, useState, useEffect, useRef } from 'react';
import { FiUpload, FiDelete } from 'react-icons/fi';



const StandardDropzone = (props) => {

  const [totalFileSize, setTotalFileSize] = useState(0);
  const [tooManyFiles, setTooManyFiles] = useState(false);
  const [filesTooBig, setFilesTooBig] = useState(false);

  const filesRef = useRef();
  filesRef.current = props.files;

  const totalFileSizeRef = useRef();
  totalFileSizeRef.current = totalFileSize;

  useEffect(() => {

    if (props.files.length < 10) {
      setTooManyFiles(false);
    }

    if (totalFileSize < 20971520) {

      setFilesTooBig(false);

    }
  }, [props.files])

  const onDrop = useCallback((acceptedFiles, rejectedFiles) => {



    if (rejectedFiles.length > 9) {
      setTooManyFiles(true);
    } else {
      setTooManyFiles(false);

    }


    if (acceptedFiles.length + filesRef.current.length > 10) {

      let excessFiles = acceptedFiles.length + filesRef.current.length - 10;

      while (excessFiles > 0) {
        
        acceptedFiles.pop();
        excessFiles = acceptedFiles.length + filesRef.current.length - 10;
      }

      setTooManyFiles(true);
      
    } else setTooManyFiles(false);


    
    let tempTotalFileSize = totalFileSizeRef.current;
    acceptedFiles.forEach(file => {
      tempTotalFileSize += parseInt(file.size);
    });

    if (rejectedFiles.length < 10 && rejectedFiles > 0) {
      setFilesTooBig(true);

    }
    if (tempTotalFileSize > 20971520) {
      setFilesTooBig(true);

      // Go through the accepted files, subtracting their size until we get below. Delete the rest
      while (tempTotalFileSize > 20971520) {
          tempTotalFileSize -= parseInt(acceptedFiles.pop().size);
      }

    } else {
      setFilesTooBig(false);
    }






    acceptedFiles.forEach(file => {
      setTotalFileSize(prevState => prevState + parseInt(file.size));
      
      props.setFiles(prevState => [...prevState, file]);
    });



  // console.log('accepted files', acceptedFiles);
  // console.log('rejected files', rejectedFiles);

  }, []);



  const { getRootProps, getInputProps, isDragActive } = useDropzone(
    {
      onDrop, maxSize: '20971520', maxFiles: '10'

    }
  );

const deleteFile = (file) => {
  props.setFiles((prevState) => {
    let newArray = prevState.filter((v) => {
      return v !== file;
    });
    return newArray;
  });
  setTotalFileSize(prevState => prevState - parseInt(file.size));

}


  return (
    <div className=''>
      <div
        className={`dropzone ${isDragActive ? 'drag-active-background-color' : ''}`}

        {...getRootProps()}
      >
        <input {...getInputProps()} />


        <a className="btn-default btn-border btn-icon" style={{ cursor: "pointer" }}>Drag or Upload Files <FiUpload /></a>


      </div>
      <ul>
        {props.files.map((file) =>

          <li className='uploaded-file-name'>{file.name} <a className=' btn btn-icon btn-large delete-file-button' onClick={()=>deleteFile(file)}> X </a></li>
        )}
      </ul>
      <p>Total size: {totalFileSize/1000000.0} MB</p>
      {tooManyFiles ? <p>Too many files. A maximum of 10 files can be uploaded.</p> : <></>}
      {filesTooBig ? <p>Exceeded 20MB upload maximum.</p> : <></>}
    </div>
  );
}

export default StandardDropzone;
