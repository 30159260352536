import React from 'react'
import { Accordion, Card, Button } from 'react-bootstrap';

const AccordionOne = ({ customStyle }) => {
    return (
        <Accordion className={`rn-accordion-style ${customStyle}`} defaultActiveKey="0">
            <Card>
                <Card.Header>
                    <Accordion.Toggle as={Button} variant="link" eventKey="0">
                        We care about you.
                    </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="0">
                    <Card.Body>We've developed indicators and strategies that have gone on to sell thousands of units. We don't care about size. No matter who you are or the scope of your project, we're here to make sure it's done perfectly and to your complete satisfaction. We treat all clients equally and with absolute respect.</Card.Body>
                </Accordion.Collapse>
            </Card>

            <Card>
                <Card.Header>
                    <Accordion.Toggle as={Button} variant="link" eventKey="1">
                        We treat our work like art.
                    </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="1">
                    <Card.Body>We take pride in our work, and believe that you should be happy and proud to use, display, or sell the final product of your custom development projects.
                    </Card.Body>
                </Accordion.Collapse>
            </Card>

            <Card>
                <Card.Header>
                    <Accordion.Toggle as={Button} variant="link" eventKey="2">
                        We're fast and experienced.
                    </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="2">
                    <Card.Body>With thousands of custom projects under our belt, we are quick to iterate and offer complimentary suggestions or features. Your project doesn't stop at your request - we will offer additions and improvements where possible according to our expertise. With faster turnarounds, you'll be able to capitalize on your ideas and bring them to market sooner.
                    </Card.Body>
                </Accordion.Collapse>
            </Card>

            <Card>
                <Card.Header>
                    <Accordion.Toggle as={Button} variant="link" eventKey="3">
                        Customer support.
                    </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="3">
                    <Card.Body>Find an issue or want a minor change for your trading tool even after accepting the final product? Just contact us. We'll fix or update anything free of charge. Most developers will charge you for any amendments made after project completion.</Card.Body>
                </Accordion.Collapse>
            </Card>

        </Accordion>
    )
}
export default AccordionOne

