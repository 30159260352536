
import { FiArrowRight, FiArrowLeft } from "react-icons/fi";
import axios from 'axios';
import { useState } from "react";


const StepBar = (props) => {


    const submitRequest = async () => {
        try {
            let formData = new FormData();
            formData.append("socketID", 2342323412);

            props.files.forEach((file) => {
                formData.append("files", file);

            })
            formData.append("projectTypes", props.selectedProjectTypes);
            formData.append("platforms", props.selectedPlatforms);
            formData.append("description", props.projectDescription);
            formData.append("senderName", props.senderName);
            formData.append("senderEmail", props.senderEmail);

            const res = await axios.post('https://traderscustom.com:5000/upload', formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                }
            });


            if (res.status == 200) {
                props.setRequestSubmitted(true);
                props.incrementActiveStep();
                props.setError(false);
            } else {

                props.setError(true);
                props.setRequestSubmitted(true);

            }
            console.log(res.data.message);

        } catch (err) {
            props.setError(true);
            console.error(err);
        }




    }


    return (

        <div className="button-group  sm--20">

            <button
                onClick={props.decrementActiveStep}

                disabled={props.activeStep === 0}
                className="btn btn-default btn-icon  btn-large forceFocusWhite" target="_blank" style={{ cursor: "pointer" }}><i className="icon"
                >
                    <FiArrowLeft />
                </i>
                {props.requestSubmitted ? "Review" : "Previous"}
            </button>

            {(props.activeStep == 4 && props.error ) || (props.activeStep == 5 && props.requestSubmitted) ? <></> :
                <button
                    onClick={props.activeStep == 4 && !props.requestSubmitted ? submitRequest : props.incrementActiveStep}
                    disabled={!props.canProgress()}
                    className="btn btn-default btn-icon  btn-large forceFocusWhite" target="_blank" style={{ cursor: "pointer" }}>
                    {props.activeStep == 4 && !props.requestSubmitted && !props.error ? "Submit Your Request" : props.activeStep < 4 && props.error ? "Next" : "Next"}
                    <i className="icon"
                    >
                        <FiArrowRight />
                    </i>
                </button>

            }
        </div>


    );
}

export default StepBar;
